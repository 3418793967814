import { Box, SxProps, Typography } from '@mui/material';
import React from 'react';
import Icon from '~/components/Icon';

import { ReactComponent as NoAlertIcon } from 'assets/icons/no-alerts.svg';
import { theme } from '~/theme';
import { I18n } from '~/i18n';

type props = {
  active?: boolean;
  sx?: SxProps;
};
function EmptyAlerts({ active, sx }: props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(1.5),
        ...sx,
      }}
    >
      <Icon name="EmptyAlert" size={30}></Icon>
      <Typography variant="body1" fontWeight={'600'}>
        {active
          ? I18n.t('alertsList.noActiveAlerts')
          : I18n.t('alertsList.noExpiredAlerts')}
      </Typography>
      <Typography variant="body1">
        {I18n.t('alertsList.noAlertsDescription', {
          alertType: active
            ? I18n.t('alertsList.active')
            : I18n.t('alertsList.expired'),
        })}
      </Typography>
    </Box>
  );
}

export default EmptyAlerts;
