import { Box, Button, Typography, useTheme } from '@mui/material';
import { center as turfGetCenter, flip } from '@turf/turf';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { useNavigate } from 'react-router-dom';

import Icon from '~/components/Icon';
import { AlertColor } from '~/components/Map/config';
import { Loader } from '~/components/UI';
import { Color } from '~/constants';
import { I18n } from '~/i18n';
import { AlertActions, useAppDispatch, useAppSelector } from '~/redux-rtk';
import { AppActions, LocationSelectionType } from '~/redux-rtk/slices/appSlice';
import { isRehydrated } from '~/redux-rtk/store';
import {
  convertWktToGeoJson,
  getDefaultBrowserLanguage,
  getNativeLanguageNames,
  getAlertColor,
} from '~/utils/alertsUtils';

import AlertDetails from './AlertDetails';

interface SelectDataType {
  value: string;
  label: string;
}

const AlertDetailView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const [availableLanguages, setAvailableLanguages] = useState<
    SelectDataType[]
  >([]);
  const [mapLoading, setMapLoading] = useState(false);

  const { map, alertDetailsPreview } = useMap();

  const mapContainer = map || alertDetailsPreview;

  const alertData = useAppSelector(state => state.alert.selectedAlertData);
  const selectedLocation = useAppSelector(
    state => state.app.data.selectedLocation,
  );

  const hasPopup = useAppSelector(
    state => state.app.data.hasPopUp
  );

  useEffect(() => {
    if (alertData) {
      const languages = getNativeLanguageNames(
        alertData?.titles.map(title => title.id),
      );
      i18n.changeLanguage(
        languages[0]?.languageCode || getDefaultBrowserLanguage(),
      );
      setAvailableLanguages(
        languages.map(item => ({
          value: item.languageCode,
          label: item.displayName,
        })),
      );
    } else {
      navigate('/alerts');
    }
    return () => {
      if (isRehydrated) {
        dispatch(AlertActions.updateSelectedAlertData(null));
      }
    };
  }, []);

  const addLayerToMap = () => {
    if (!alertData?.active) {
      mapContainer.getMap().addSource('alert-geojson', {
        type: 'geojson',
        data: convertWktToGeoJson(alertData?.geom),
      });
      mapContainer.getMap().addLayer({
        id: 'alert_polygon_geom',
        type: 'fill',
        source: 'alert-geojson',
        minzoom: 0,
        maxzoom: 22,
        paint: {
          'fill-color': `${getAlertColor(alertData)}`,
          'fill-opacity': 0.5,
          'fill-outline-color': `${getAlertColor(alertData)}`,
        },
      });
      mapContainer.getMap().addLayer({
        id: 'alert_polygon_highlighted_geom',
        type: 'line',
        source: 'alert-geojson',
        paint: {
          'line-opacity': 0.7,
          'line-width': 4.5,
          'line-color':
            alertData.source === 'EVAC' ? Color.LeafGreen : AlertColor,
        },
      });
    }
    const pointCenter = turfGetCenter(
      convertWktToGeoJson(alertData?.geom) as any,
    );

    // we need the coordintes in the form 'y,x'
    const center = flip(pointCenter);

    // 'goto location' is handled in src/components/Map/Map.tsx
    dispatch(
      AppActions.setInitialLocation({
        type: LocationSelectionType.Alert,
        position: center.geometry.coordinates,
        zoom: 14,
        settings: {
          hasFlyToAnimation: true,
        },
        id: alertData.campaign_id ?? selectedLocation?.id,
        zoneId: selectedLocation?.zoneId ?? selectedLocation?.id,
        prevPosition:
          selectedLocation?.prevPosition ?? selectedLocation?.position,
        details: {
          zonesImpacted: alertData.zonesImpacted,
        },
      }),
    );
    setMapLoading(false);
  };

  const handleMapLayers = () => {
    if (mapContainer?.getStyle()) {
      addLayerToMap();
    } else {
      mapContainer?.once('styledata', () => {
        addLayerToMap();
      });
    }
  };

  useEffect(() => {
    if (!mapContainer || !alertData?.geom) return;
    if (mapContainer.loaded()) {
      handleMapLayers();
    } else {
      mapContainer.on('load', handleMapLayers);
    }
    return () => {
      /**
       * even though we have used 'optional chaining' here, in some cases maplibre throws -
       * - error: 'cannot read properties of undefined (reading removelayer)'.
       * easiest way to handle this scenario is to wrap it in a try/catch block
       */
      if (!alertData?.active) {
        try {
          if (mapContainer) {
            mapContainer?.getMap?.()?.removeLayer?.('alert_polygon_geom');
            mapContainer
              ?.getMap?.()
              ?.removeLayer?.('alert_polygon_highlighted_geom');
            mapContainer?.getMap?.()?.removeSource?.('alert-geojson');
          }
        } catch (err) {
          console.log(err);
        }
      }
    };
  }, [alertData, mapContainer]);

  const shouldDisplayBackButton = !(
    alertData?.navigatedFrom === 'location' && alertData?.source === 'EVAC'
  );

  const handleBackButtonClick = () => {
    if (alertData?.navigatedFrom !== 'location') {
      return navigate('/alerts');
    }

    if (selectedLocation?.prevPosition) {
      if (!hasPopup) {
        dispatch(
          AppActions.selectLocation({
            ...selectedLocation,
            settings: { hasPopup: true },
          }),
        );
      }
      return navigate(-1);
    }

    navigate('/search');
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: `${shouldDisplayBackButton ? '100%' : 'auto'}`,
        padding: shouldDisplayBackButton ? theme.spacing(2) : 0,
        overflowY: 'auto',
      }}
    >
      {shouldDisplayBackButton && (
        <Button
          variant="outlined"
          onClick={handleBackButtonClick}
          sx={{
            padding: theme.spacing(1),
            backgroundColor: theme.palette.grey[50],
            borderRadius: '30px',
            color: theme.palette.secondary.main,
            border: 'none',
            '&:hover': {
              border: 'none',
            },
          }}
        >
          <Icon
            name="ArrowSmallLeft"
            size={18}
            color={theme.palette.secondary.main}
          />
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            {I18n.t('common.back')}
          </Typography>
        </Button>
      )}
      {mapLoading ? (
        <Box
          sx={{
            marginTop: theme.spacing(20),
          }}
        >
          <Loader />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            marginTop: shouldDisplayBackButton ? 2 : 0,
          }}
        >
          <AlertDetails
            alertDetails={alertData}
            availableLanguages={availableLanguages}
          />
        </Box>
      )}
    </Box>
  );
};

export default AlertDetailView;
