import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage

import { BaseMapApi, BaseZonehavenApi, gpAdminApi } from './api';
import { alertSlice, mapSlice, userSlice } from './slices';
import { appSlice } from './slices/appSlice';

declare module 'redux-persist' {
  interface PersistorState {
    _persist: {
      version: number;
      rehydrated: boolean;
    };
  }
}

const alertPersistConfig = {
  key: 'alert',
  storage,
  whitelist: ['selectedAlertData'],
};

const persistedAppReducer = persistReducer(
  alertPersistConfig,
  alertSlice.reducer,
);

export const store = configureStore({
  reducer: {
    [appSlice.name]: appSlice.reducer,
    [alertSlice.name]: persistedAppReducer,
    [mapSlice.name]: mapSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [BaseMapApi.reducerPath]: BaseMapApi.reducer,
    [BaseZonehavenApi.reducerPath]: BaseZonehavenApi.reducer,
    [gpAdminApi.reducerPath]: gpAdminApi.reducer,
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      BaseMapApi.middleware,
      BaseZonehavenApi.middleware,
      gpAdminApi.middleware,
    );
  },
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// Track the rehydration status
let isRehydrated = false;

persistor.subscribe(() => {
  const state = persistor.getState();
  if (state?._persist?.rehydrated) {
    isRehydrated = true;
    console.log('Store has been rehydrated!');
  }
});

export { isRehydrated };
