import {
  Autocomplete,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import Icon from '~/components/Icon';
import { Color } from '~/constants';
import { I18n } from '~/i18n';
import { useAppDispatch, useAppSelector } from '~/redux-rtk/hooks';
import { AlertActions } from '~/redux-rtk/slices/alertSlice';
import { isZoneId } from '~/utils';

export default function SearchZoneId() {
  const dispatch = useAppDispatch();
  const zoneIdSelected = useAppSelector(
    state => state.alert.expiredAlertFilters.zoneId,
  );
  const updateFilters = (value: { zoneId: string }) => {
    dispatch(AlertActions.updateExpiredAlertFilters(value));
  };

  // the api will get called as soon as we set redux state so -
  //- the local state is used to show the options
  const [zoneSearchOption, setZoneSearchOption] = useState(zoneIdSelected);

  const searchOptions = () => {
    if (isZoneId(zoneSearchOption)) {
      return [
        {
          label: `${I18n.t('zones.zone')}: ${zoneSearchOption.toUpperCase()}`,
          value: zoneSearchOption,
        },
      ];
    }
    return [];
  };

  useEffect(() => {
    setZoneSearchOption(zoneIdSelected)
  }, [zoneIdSelected])

  return (
    <Grid container width={'max-content'}>
      <Grid item>
        <InputLabel shrink={false} htmlFor={'autocomplete'}>
          <Typography variant="body2" color={'gray'}>
            {I18n.t('alertsList.zoneId')}
          </Typography>
        </InputLabel>
        <Autocomplete
          id="autocomplete"
          freeSolo
          fullWidth
          size="small"
          inputValue={zoneSearchOption?.length ? zoneSearchOption : ''}
          onChange={(e, selected: any) => {
            // when user types the value and hits `Enter` selected is a string with the value
            // else it's an object with label and value
            const zoneId =
              typeof selected === 'string'
                ? selected?.toUpperCase()
                : selected?.value?.toUpperCase();
            updateFilters({
              zoneId,
            });
          }}
          onInputChange={(e, newValue, reason) => {
            if (reason === 'clear') {
              updateFilters({ zoneId: '' });
            }
            setZoneSearchOption(newValue);
          }}
          sx={{
            minWidth: '170px',
            '& .MuiInputBase-root': {
              minHeight: '0',
            },
          }}
          options={searchOptions()}
          getOptionLabel={option => option?.label || []}
          renderInput={params => (
            <TextField
              {...params}
              label=""
              fullWidth
              placeholder={I18n.t('alertsList.searchZoneId')}
              size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start" sx={{ ml: '5px' }}>
                      <Icon name="Search" color={Color.DarkBlue} size={20} />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
