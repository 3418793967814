import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';
import { union } from 'lodash';

import { updateUserLocationThunk } from 'redux-rtk/thunks';
import { appMapConfig } from '~/components/Map/config';
import {
  Alert,
  ArrivalPoint,
  EvacuationCenter,
  Hazard,
  RoadClosure,
  Zone,
} from '~/components/Map/types';
import { AlertDetails } from '~/pages/AlertsList/types';

export enum HazardsSortMethod {
  Distance = 'distance',
  Name = 'name',
  Date = 'date',
}

interface ZoneBasicDetails {
  status: string;
}

export enum LocationSelectionType {
  Zone = 'zone',
  Hazard = 'hazard',
  Position = 'position',
  RallyPoint = 'rallyPoint',
  RoadClosure = 'roadClosure',
  TrafficControlPoint = 'trafficControlPoint',
  ArrivalPoint = 'arrivalPoint',
  AnimalShelter = 'animalShelter',
  EvacuationCenter = 'evacuationCenter',
  OnRamp = 'onRamp',
  ResourceCenter = 'resourceCenter',
  Shelter = 'shelter',
  Alert = 'alert',
}

export const ArrivalPointTypeMapping = {
  1: LocationSelectionType.OnRamp,
  2: LocationSelectionType.EvacuationCenter,
  3: LocationSelectionType.AnimalShelter,
  4: LocationSelectionType.ResourceCenter,
  5: LocationSelectionType.Shelter,
  6: LocationSelectionType.RallyPoint,
};
export interface LocationSelection {
  id?: any;
  type: LocationSelectionType;
  details?:
    | Zone
    | ZoneBasicDetails
    | Hazard
    | EvacuationCenter
    | RoadClosure
    | ArrivalPoint
    | Alert;
  position: Position;
  zoom?: number;
  bbox?: [[number, number], [number, number]];
  settings?: {
    hasPopup?: boolean;
    hasFlyToAnimation?: boolean;
  };
  prevPosition?: Position;
  zoneId?: string;
}

export interface AppState {
  data: {
    completedAppSetupSteps: AppSetupSteps[];
    selectedLocation: LocationSelection | null;
    selectedHazardSort: HazardsSortMethod;
    hazardSearchTerm: string;
    featureCollection: LocationSelection[];
    hasPopUp: boolean
  };
  onboardingModalIsVisible: boolean;
}

const initialState: AppState = {
  data: {
    completedAppSetupSteps: [],
    selectedLocation: null,
    selectedHazardSort: HazardsSortMethod.Distance,
    hazardSearchTerm: '',
    featureCollection: null,
    hasPopUp: true,
  },
  onboardingModalIsVisible: false,
};

export enum AppSetupSteps {
  SetInitialLocationSelection = 'setInitialLocationSelection',
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    addCompletedAppSetupSteps: (
      state,
      action: PayloadAction<AppSetupSteps | AppSetupSteps[]>,
    ) => {
      if (Array.isArray(action.payload)) {
        state.data.completedAppSetupSteps = union(
          state.data.completedAppSetupSteps,
          action.payload,
        );
        return;
      }

      state.data.completedAppSetupSteps = union(
        state.data.completedAppSetupSteps,
        [action.payload],
      );
    },
    removeCompletedAppSetupSteps: (
      state,
      action: PayloadAction<AppSetupSteps | AppSetupSteps[]>,
    ) => {
      if (Array.isArray(action.payload)) {
        state.data.completedAppSetupSteps =
          state.data.completedAppSetupSteps.filter(
            step => !action.payload.includes(step),
          );
        return;
      }

      state.data.completedAppSetupSteps =
        state.data.completedAppSetupSteps.filter(
          step => step !== action.payload,
        );
    },
    selectLocation(state, action: PayloadAction<LocationSelection | null>) {
      if (!action.payload) {
        state.data.selectedLocation = null;
        return;
      }

      const payloadWithDefaults: LocationSelection = {
        ...action.payload,
        zoom:
          action.payload?.zoom ?? appMapConfig.zoom[action.payload.type] ?? 14,
        settings: {
          hasFlyToAnimation:
            action.payload?.settings?.hasFlyToAnimation ?? true,
        },
      };
      state.data.hasPopUp = action.payload?.settings?.hasPopup ?? true
      state.data.selectedLocation = payloadWithDefaults;
    },
    selectHazardSort(state, action: PayloadAction<HazardsSortMethod>) {
      state.data.selectedHazardSort = action.payload;
    },
    updateHazardSearchTerm(state, action: PayloadAction<string>) {
      state.data.hazardSearchTerm = action.payload;
    },
    updateFeatureCollection(state, action: PayloadAction<LocationSelection[]>) {
      state.data.featureCollection = action.payload;
    },
    setInitialLocation(state, action: PayloadAction<LocationSelection>) {
      appSlice.caseReducers.selectLocation(state, {
        ...action,
        payload: {
          ...action.payload,
          settings: {
            ...action.payload.settings,
            hasFlyToAnimation: false,
          },
        },
      });
      appSlice.caseReducers.addCompletedAppSetupSteps(state, {
        payload: AppSetupSteps.SetInitialLocationSelection,
        type: 'addCompletedAppSetupSteps',
      });
    },
    setOnboardingModalVisible(state, action: PayloadAction<boolean>) {
      state.onboardingModalIsVisible = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(updateUserLocationThunk.fulfilled, (state, action) => {
      const url = new URL(window.location.href);

      // latlon params have priority over user location
      if (
        url.searchParams.has('latlon') ||
        state.data.completedAppSetupSteps.includes(
          AppSetupSteps.SetInitialLocationSelection,
        )
      ) {
        return state;
      }

      state.data.selectedLocation = {
        type: LocationSelectionType.Position,
        position: action.payload.coordinates,
        zoom: appMapConfig.zoom[LocationSelectionType.Position],
      };
    });
  },
});

export const AppActions = appSlice.actions;
