import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { capitalize } from 'lodash';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import RebrandingHeader from '~/components/Marketing/RebrandingHeader';
import { Color } from '~/constants';
import {
  FaqUrl,
  GenasysHomeUrl,
  GenasysPrivacyPolicyUrl,
} from '~/constants/urls';
import { ModalsContext, ModalsContextIds } from '~/context';
import { isFeedbackButtonEnabled, isRebrandingAnnounceEnabled } from '~/flags';
import { useIsMobileDevice } from '~/hooks';
import { I18n } from '~/i18n';
import { Card } from '~/layouts/Card';
import { Page } from '~/layouts/Page';
import { theme } from '~/theme';

import CardItem from './CardItem';

const linkStyles: CSSProperties = {
  color: theme.palette.primary.main,
  textDecoration: 'none',
  fontWeight: 'bold',
  cursor: 'pointer',
};

const About = () => {
  const isMobile = useIsMobileDevice();
  const theme = useTheme();
  const modalsContext = useContext(ModalsContext);
  // Whether or not our viewport is any smaller than large
  const isSmaller = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  return (
    <Page>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box maxWidth={800}>
          <Typography
            textAlign={'left'}
            variant="h1"
            sx={{ mb: 1, color: theme.palette.secondary.main, width: '100%' }}
          >
            {I18n.t('about.title')}
          </Typography>
          <Grid
            container
            rowSpacing={3}
            sx={{
              margin: '0 auto',
              width: '100%',
            }}
          >
            <Grid item md={12} lg={12}>
              <Card
                hasShadow={false}
                sx={{
                  position: 'relative',
                  overflow: 'hidden',
                  textAlign: 'center',
                  marginRight: isMobile ? 0 : 3,
                  padding: isMobile ? '49px 24px 24px 24px' : '0',
                }}
              >
                {isRebrandingAnnounceEnabled && <RebrandingHeader />}

                <Typography
                  variant="body2"
                  textAlign={'left'}
                  lineHeight={'22px'}
                >
                  {I18n.t('about.disclaimer.paragraph1')}
                  <a
                    href={GenasysHomeUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={linkStyles}
                  >
                    {I18n.t('about.disclaimer.link')}
                  </a>
                  {I18n.t('about.disclaimer.paragraph2')}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ mt: 2 }}
                  textAlign={'left'}
                  lineHeight={'22px'}
                >
                  {I18n.t('about.disclaimer.paragraph3', {
                    appName: I18n.t('common.appName'),
                  })}
                </Typography>
              </Card>
            </Grid>

            <Grid
              item
              xs={12}
              spacing={2}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                display: 'grid',
                gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
                gap: theme.spacing(2),
              }}
            >
              <CardItem>
                <Typography variant="h6" sx={{ mt: 1 }}>
                  {I18n.t('about.faqLabel')}
                </Typography>

                <Typography variant="body2" sx={{ mt: 1 }}>
                  {I18n.t('about.learnMoreAbout', {
                    appName: I18n.t('common.appName'),
                  })}
                </Typography>

                <Typography variant="body2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={FaqUrl}
                    style={linkStyles}
                  >
                    {I18n.t('about.viewFAQ')}
                  </a>
                </Typography>
              </CardItem>

              <CardItem>
                {/* <PrivacyIcon role="img" aria-hidden="true" /> */}
                <Typography variant="h6" sx={{ mt: 1 }}>
                  {I18n.t('about.privacyLabel')}
                </Typography>

                <Typography variant="body2" sx={{ mt: 1 }}>
                  {I18n.t('about.privacy')}
                </Typography>

                <Typography variant="body2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={GenasysPrivacyPolicyUrl}
                    style={linkStyles}
                  >
                    {I18n.t('about.accessPrivacy')}
                  </a>
                </Typography>
              </CardItem>

              <CardItem>
                {/* <PrivacyIcon role="img" aria-hidden="true" /> */}
                <Typography variant="h6" sx={{ mt: 1 }}>
                  {I18n.t('about.mobileApp')}
                </Typography>

                <Typography variant="body2" sx={{ mt: 1 }}>
                  {I18n.t('about.availableToDownload')}
                </Typography>

                <Typography variant="body2">
                  <a
                    style={linkStyles}
                    tabIndex={0}
                    onClick={() => navigate('/download')}
                  >
                    {I18n.t('common.learnMore')}
                  </a>
                </Typography>
              </CardItem>

              <CardItem>
                <Typography variant="body2" sx={{ mt: 1 }} lineHeight={'22px'}>
                  {I18n.t('about.disclaimer.emergency')}
                </Typography>
              </CardItem>
            </Grid>
          </Grid>

          <Box
            sx={{
              position: isSmaller ? 'static' : 'relative',
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              width: '100%',
              my: 3,
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{ display: 'inherit', flexDirection: 'inherit' }}
              component="footer"
              role="contentinfo"
            >
              <Typography variant="caption">
                {isRebrandingAnnounceEnabled
                  ? I18n.t('common.rebrandingMain', {
                      appName: I18n.t('common.appName'),
                    })
                  : I18n.t('common.appName')}
                {process.env.NODE_ENV !== 'production'
                  ? ` ${capitalize(process.env.NODE_ENV)} - `
                  : ' '}
                {I18n.t('common.version')} {process.env.REACT_APP_VERSION}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {isFeedbackButtonEnabled && (
        <Button
          variant="contained"
          onClick={() =>
            modalsContext.setCurrentOpenedModal(ModalsContextIds.Feedback)
          }
          sx={{
            position: 'absolute',
            bottom: isSmaller ? 16 : 50,
            px: 2,
            right: isSmaller ? 16 : 200,
            zIndex: 5,
          }}
        >
          <Typography fontWeight={'bold'} color={Color.White}>
            {I18n.t('about.needTechnicalHelp')}
          </Typography>
        </Button>
      )}
    </Page>
  );
};

export default About;
