import { ListItemIcon, Box } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography, Grid, TextField, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import Icon from '~/components/Icon';
import { Color } from '~/constants';
import { zoneStatusData } from '~/constants/zoneStatus';
import { I18n } from '~/i18n';
import { useAppDispatch, useAppSelector } from '~/redux-rtk/hooks';
import { AlertActions } from '~/redux-rtk/slices/alertSlice';
import { theme } from '~/theme';

const getMenuItem = (
  iconName: string,
  color: string,
  value: number,
  label: string,
) => {
  return (
    <MenuItem
      value={value}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        width: '100%',
        gap: '5px',
        padding: theme.spacing(1),
      }}
    >
      <ListItemIcon style={{ minWidth: '0' }}>
        <Icon name={iconName} color={color} size={20}></Icon>
      </ListItemIcon>
      <Typography variant="body1">{label}</Typography>
    </MenuItem>
  );
};

export default function StatusSelector() {
  const dispatch = useAppDispatch();
  const statusSelected = useAppSelector(
    state => state.alert.expiredAlertFilters.zoneStatus,
  );
  const updateFilters = (value: { zoneStatus: number }) => {
    dispatch(AlertActions.updateExpiredAlertFilters(value));
  };

  const handleChange = (event: any) => {
    updateFilters({ zoneStatus: event.target.value });
  };

  const statusOptions = () => {
    const options = Object.values(zoneStatusData).reduce(
      (accumulater, current, index) => {
        //alerts with status 'Training' are not available in alertList.
        if (current.status === 'Training') return accumulater;
        return [
          ...accumulater,
          getMenuItem('StatusDot', current.color, index + 1, current.status),
        ];
      },
      [],
    );
    return [getMenuItem('SelectAll', '', 0, 'All'), ...options];
  };

  return (
    <Grid container width={'max-content'}>
      <Grid item>
        <InputLabel shrink={false} htmlFor={'autocomplete'}>
          <Typography variant="body2" color={'gray'}>
            {I18n.t('alertsList.zoneStatus')}
          </Typography>
        </InputLabel>
        <Select
          id="status-select"
          placeholder=""
          value={statusSelected}
          onChange={data => handleChange(data)}
          variant="outlined"
          IconComponent={KeyboardArrowDownIcon}
          size="small"
          sx={{
            '.MuiSelect-select': { display: 'flex', gap: '5px' },
            minHeight: 0,
            minWidth: '180px',
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                border: `1px solid ${Color.Grey200}`, // Border around the dropdown
                borderRadius: 1,
                marginTop: 1,
              },
            },
          }}
        >
          {statusOptions()}
        </Select>
      </Grid>
    </Grid>
  );
}
