import { Close } from '@mui/icons-material';
import {
  Box,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { Fragment, PropsWithChildren } from 'react';

import { I18n } from 'i18n';
import { Loader } from '~/components/Loader';

import { Card } from '../../../../layouts/Card';
import { PropsWithSx } from '../../../../utils';
import { MotionDiv } from '../../../MotionDiv';
import { H3Styled } from '~/components/common/Styled/H3Styled';


interface CardStyles {
  width?: number | string;
  height?: number | string;
  minWidth?: number | string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  backgroundColor?: string;
}
interface Props {
  title?: string;
  minHeight?: number;
  Icon: React.ReactNode;
  isFetching?: boolean;
  iconTitle: string;
  iconSubtitle?: string;
  checkedFollowButton?: boolean;
  closeButtonLabel: string;
  iconBackgroundColor?: string;
  isFollowButtonVisible?: boolean;
  isCloseButtonVisible?: boolean;
  sections?: Array<{
    title: string;
    content: string;
  }>;

  onClose?: () => void;
  onFollowChange?: (boolean) => void;
  cardStyles? : CardStyles;
}

export const BaseMapCard = ({
  sx,
  title,
  onClose,
  children,
  minHeight = 100,
  isFetching,
  onFollowChange,
  checkedFollowButton = false,
  closeButtonLabel,
  isFollowButtonVisible = false,
  isCloseButtonVisible = true,
  Icon,
  iconTitle = 'Default text',
  iconSubtitle,
  iconBackgroundColor = 'primary.main',
  sections,
  cardStyles
}: PropsWithSx<PropsWithChildren<Props>>) => {
  return (
    <Card
      sx={{
        minWidth: 300,
        minHeight,
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 2,
          right: 2,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        {isFollowButtonVisible && (
          <FormControlLabel
            value="start"
            componentsProps={{
              typography: {
                variant: 'body2',
                color: 'text.secondary',
              },
            }}
            control={
              <Switch
                sx={{ mr: 1 }}
                color="primary"
                checked={checkedFollowButton}
                onChange={(e, checked) => onFollowChange(checked)}
                inputProps={{ 'aria-label': 'Follow or unfollow zone' }}
              />
            }
            labelPlacement="start"
            label={
              !checkedFollowButton
                ? I18n.t('zoneDetails.followZone')
                : I18n.t('zoneDetails.followingZone')
            }
          />
        )}
        {isCloseButtonVisible && (
          <IconButton
            onClick={onClose}
            sx={{ marginLeft: 0 }}
            title={closeButtonLabel}
          >
            <Close
              color="secondary"
              role="img"
              titleAccess={closeButtonLabel}
            />
          </IconButton>
        )}
      </Box>

      <AnimatePresence exitBeforeEnter>
        <MotionDiv key={`${isFetching}`}>
          {isFetching ? (
            <Box
              sx={{
                minHeight,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Loader height={25} width={100} />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  pr: 1,
                  mb: 1,
                  width: '100%',
                  borderRadius: 1,
                  display: 'flex',
                }}
              >
                <Box
                  sx={{...cardStyles,
                    borderRadius: 1,
                  }}
                >
                  {Icon}
                </Box>

                <Box
                  sx={{
                    px: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <H3Styled variant="h2">{iconTitle}</H3Styled>
                  {iconSubtitle && (
                    <Typography variant="body2">{iconSubtitle}</Typography>
                  )}
                </Box>
              </Box>

              {sections?.map(({ title, content }, idx) => (
                <Fragment key={idx}>
                  <Typography variant="caption">{title}</Typography>
                  <Typography variant="body1">{content}</Typography>
                </Fragment>
              ))}

              {children}
            </>
          )}
        </MotionDiv>
      </AnimatePresence>
    </Card>
  );
};
