import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { trackUserUnfollowedZone } from '~/analytics/fns';
import { MaxAllowedNumberOfZonesToFollow, NotFound } from '~/constants';
import { ModalsContext, ModalsContextIds } from '~/context';
import { I18n } from '~/i18n';
import {
  useAppSelector,
  useFetchZoneDetailsQuery,
  useFetchZoneDetailsWithCoordsQuery,
} from '~/redux-rtk';
import {
  LocalStorageFollowedZone,
  LocalStorageKeys,
  LocationOrigin,
} from '~/types';
import { PropsWithSx, getZoneIdFromZoneName } from '~/utils';

import { ZoneFooter } from './ZoneFooter';
import { ZoneName } from './ZoneName';
import { ZoneStatus } from './ZoneStatus';
import { AsyncMapCard } from '../AsyncMapCard';
import SelectedZoneCard from './SelectedZoneCard';

interface ZoneCardProps {
  zoneId: string;
  alias?: string;
  isUserZone?: boolean;
  onClose?: () => void;
  isCloseButtonVisible?: boolean;
  isFollowButtonVisible?: boolean;
  showZoneSelected?: boolean;
}

export const EvacAlertCard = ({
  sx,
  alias,
  zoneId,
  onClose,
  isFollowButtonVisible = false,
  showZoneSelected,
}: PropsWithSx<ZoneCardProps>) => {
  const { enqueueSnackbar } = useSnackbar();

  const { setCurrentOpenedModal } = useContext(ModalsContext);

  const [followedZones, setFollowedZones] = useLocalStorage<
    LocalStorageFollowedZone[]
  >(LocalStorageKeys.FollowedZones, []);

  const isZoneDetailsPage = useMatch('/zones/:id');

  const { coordinates, origin } = useAppSelector(
    state => state.user.data.currentLocation,
  );

  const { data: zoneDetails, isFetching } = useFetchZoneDetailsQuery(zoneId);

  const { data: userZoneDetails } = useFetchZoneDetailsWithCoordsQuery(
    coordinates,
    {
      skip:
        !coordinates ||
        !coordinates?.length ||
        origin !== LocationOrigin.Browser,
    },
  );

  const isUserZone = userZoneDetails?.zone?.identifier === zoneId;

  const zoneData = zoneDetails?.zone ?? userZoneDetails?.zone;
  const zoneTitle = getZoneIdFromZoneName(zoneData?.identifier);

  return (
    <AsyncMapCard
      onClose={onClose}
      closeButtonLabel={`Close Zone ${zoneTitle}`}
      isFetching={isFetching}
      sx={{ ...sx }}
      onFollowChange={isFollowed => {
        setFollowedZones(prev => {
          if (!isFollowed) {
            trackUserUnfollowedZone(zoneId);
            return prev.filter(el => el.zoneId !== zoneId);
          }

          if (prev.length === MaxAllowedNumberOfZonesToFollow) {
            enqueueSnackbar(
              I18n.t('followZoneModal.maxFollowedZonesReached', {
                number: MaxAllowedNumberOfZonesToFollow,
              }),
              {
                variant: 'error',
              },
            );
            return prev;
          }

          setCurrentOpenedModal(ModalsContextIds.FollowZone, {
            zoneId,
          });
          return prev;
        });
      }}
      isFollowButtonVisible={isFollowButtonVisible}
      checkedFollowButton={!!followedZones.find(el => el.zoneId === zoneId)}
    >
      {zoneData && (
        <>
          {showZoneSelected ? (
            <SelectedZoneCard zoneId={zoneId} />
          ) : (
            <ZoneStatus zone={zoneData} sx={{ mt: 1 }} />
          )}
        </>
      )}
    </AsyncMapCard>
  );
};
