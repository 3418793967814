import { Box } from '@mui/material';
import { point } from '@turf/helpers';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ReactComponent as NoAlertIcon } from 'assets/icons/no-alerts.svg';
import { I18n } from 'i18n';
import { CommonMessageCard } from '~/components/CommonMessageCard';
import { Loader } from '~/components/Loader';
import { MapPreview } from '~/components/MapPreview';
import { SelectedLocation } from '~/components/SelectedLocation/SelectedLocation';
import { MiscZoneInfo } from '~/components/ZoneDetails/MiscZoneInfo';
import { PointsOfInterestContainer } from '~/components/ZoneDetails/PointsOfInterest';
import { Page } from '~/layouts/Page';
import {
  useAppDispatch,
  useAppSelector,
  useFetchAlertsQuery,
  useFetchZoneDetailsQuery,
  useFetchZonesByRadiusQuery,
  ZonesApi,
} from '~/redux-rtk';
import { AppActions, LocationSelectionType } from '~/redux-rtk/slices/appSlice';
import { filterEvacAlertsByGeometry } from '~/utils/alertsUtils';

import { CommonAlertCard } from './CommonAlertCards';

export const LocationInformation = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const featureCollection = useAppSelector(
    state => state.app.data.featureCollection,
  );

  const selectedLocation = useAppSelector(
    state => state.app.data.selectedLocation,
  );

  const searchTerm = useMemo(() => searchParams.get('search'), [searchParams]);

  const shouldSkipSearch = useMemo(() => {
    return !(
      (searchTerm || !featureCollection?.length) &&
      selectedLocation?.type === LocationSelectionType.Position &&
      selectedLocation?.position.length > 0
    );
  }, [searchTerm, featureCollection, selectedLocation]);

  const evacAlertData = useFetchZonesByRadiusQuery(
    {
      coordinate: {
        lat: selectedLocation?.position[1],
        lon: selectedLocation?.position[0],
      },
      bufferInKm: 1,
    },
    { skip: shouldSkipSearch },
  );

  const gemAlertData = useFetchAlertsQuery(
    {
      pageSettings: {
        page: 0,
        size: 1000,
      },
      filters: {
        searchGeom: selectedLocation?.position && {
          xmax: selectedLocation?.position[1],
          xmin: selectedLocation?.position[1],
          ymax: selectedLocation?.position[0],
          ymin: selectedLocation?.position[0],
        },
        activeOnly: true,
      },
      sortMethod: {
        type: 'time',
        direction: 'DESC',
      },
    },
    { skip: shouldSkipSearch },
  );

  useEffect(() => {
    if (selectedLocation && !shouldSkipSearch) {
      handleAlertDataForSearch();
    }
  }, [evacAlertData?.data, gemAlertData?.data]);

  const handleAlertDataForSearch = () => {
    if (!selectedLocation?.position) return;

    const { position } = selectedLocation;
    const geometry = point([position[1], position[0]]);
    if (!geometry) return;

    const formattedEvacAlertData = filterEvacAlertsByGeometry(
      evacAlertData?.data?.zones,
      geometry,
    );

    const formattedGemAlert = (gemAlertData?.data?.content ?? [])
      .filter(item => item?.source === 'ALERT')
      .map(item => ({
        id: item?.campaign_id,
        type: LocationSelectionType?.Alert,
        position,
      }));

    const featureData = [
      ...formattedGemAlert,
      formattedEvacAlertData?.[0] && {
        id: formattedEvacAlertData[0].identifier,
        type: LocationSelectionType?.Zone,
        position,
        details: { status: formattedEvacAlertData[0].status },
      },
    ].filter(Boolean);

    dispatch(AppActions.updateFeatureCollection(featureData));
  };

  const getAlertsFromEvac = () => {
    return featureCollection?.filter(
      (collection: any) =>
        collection.type === LocationSelectionType.Zone &&
        collection?.details?.status?.toLowerCase() !== 'normal',
    );
  };

  const getZoneData = () => {
    return featureCollection?.filter(
      (collection: any) => collection.type === LocationSelectionType.Zone,
    );
  };

  const getAlertsFromALERT = () => {
    return featureCollection
      ?.filter(
        (collection: any) => collection.type === LocationSelectionType.Alert,
      )
      .map(collection => {
        return { id: collection.id, type: collection.type };
      });
  };

  const alertResponse = ZonesApi.useFetchAlertsQuery(
    {
      pageSettings: {
        page: 0,
        size: 1000,
      },
      filters: { campaign_id: getAlertsFromALERT()?.map(alert => alert.id) },
      sortMethod: {
        type: 'time',
        direction: 'DESC',
      },
    },
    { skip: getAlertsFromALERT()?.length < 1 },
  );

  const { data, isLoading, isFetching } = useFetchZoneDetailsQuery(
    getZoneData()?.[0]?.id,
    {
      skip: !getZoneData()?.[0]?.id,
      selectFromResult: result => ({
        ...result,
        data: !getZoneData()?.[0]?.id ? null : result.data,
      }),
    },
  );

  useEffect(() => {
    /**  when the address search is used to select a location, we need to set the id from here so that -
     *   - in the Map.tsx we will have the id to show the zoneId in the map popup!
     *   this is not necessary in selecting a location using 'click', as we would be getting the zoneId -
     *   - from the features from the layer itself!
     **/
    if (!data && !selectedLocation?.prevPosition) {
      dispatch(
        AppActions.selectLocation({
          ...selectedLocation,
          id: null,
          type: LocationSelectionType.Position,
          position:
            selectedLocation?.prevPosition ?? selectedLocation?.position,
          prevPosition: null,
        }),
      );
      return;
    }

    if (!selectedLocation?.prevPosition) {
      const zoneId = selectedLocation.zoneId ?? data?.zone?.identifier;
      if (zoneId !== selectedLocation.id) {
        dispatch(
          AppActions.selectLocation({
            ...selectedLocation,
            id: zoneId,
          }),
        );
      }
    }
  }, [data]);

  const shouldShowEvacData =
    data && !!getAlertsFromEvac()?.length && !isFetching;
  const shouldShowAlertData =
    alertResponse?.data &&
    !!getAlertsFromALERT()?.length &&
    !alertResponse?.isFetching;

  return (
    <Page hasBackButton onBackPressNavigateTo={'/search'}>
      <SelectedLocation
        showHeader
        zoneId={getZoneData()?.[0]?.id}
        minHeight={150}
        greyBackGround
      />
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        {(isFetching || alertResponse.isFetching) && <Loader />}
        <CommonAlertCard
          evacDetails={{
            ...data,
            shouldShowEvacData,
            zoneId: getAlertsFromEvac()?.[0]?.id,
          }}
          alertDetails={{
            ...alertResponse,
            shouldShowAlertData,
          }}
        />
        {!shouldShowEvacData &&
          !shouldShowAlertData &&
          !isFetching &&
          !alertResponse.isFetching && (
            <CommonMessageCard
              title={I18n.t('search.noAlerts')}
              body={I18n.t('search.noActiveIncidents')}
              icon={<NoAlertIcon aria-hidden="true" role="img" />}
            />
          )}
        <MapPreview mapId="locationPreview" />
        {data?.zone && (
          <PointsOfInterestContainer zoneId={data?.zone?.identifier} />
        )}
        {data?.zone && <MiscZoneInfo zone={data?.zone} />}
      </Box>
    </Page>
  );
};
