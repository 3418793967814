import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import * as React from 'react';

import Icon from '~/components/Icon';
import { Color } from '~/constants';
import { I18n } from '~/i18n';
import { theme } from '~/theme';

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  icon?: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: I18n.t('alertsList.name'),
  },
  {
    id: 'county',
    numeric: true,
    disablePadding: false,
    label: I18n.t('alertsList.county'),
    icon: 'County',
  },
  {
    id: 'critical',
    numeric: false,
    disablePadding: false,
    label: I18n.t('alertsList.critical'),
    icon: 'Warning',
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: I18n.t('alertsList.date'),
    icon: 'Calendar',
  },
  {
    id: 'time',
    numeric: true,
    disablePadding: false,
    label: I18n.t('alertsList.time'),
    icon: 'Clock',
  },
  {
    id: 'link',
    numeric: true,
    disablePadding: false,
    label: I18n.t('alertsList.link'),
  },
];

export default function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell key={headCell.id} align={'left'}>
            <Box
              sx={{
                display: 'flex',
                gap: theme.spacing(1),
              }}
            >
              {headCell.icon && (
                <Icon
                  name={headCell.icon}
                  size={14}
                  color={Color.NeutralGrey}
                />
              )}
              <Typography variant="body2" fontWeight={600}>
                {headCell.label}
              </Typography>
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
