import { ZoneDetails } from '~/components/Map/types';
import { RequestStatus } from '../../constants';
import { IUserLocation, LocationOrigin } from '../../types';
import { updateUserLocationThunk } from '../thunks';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface UserState {
  data: {
    currentLocation: IUserLocation;
    zoneDetails?: ZoneDetails;
  };
  requests: {
    getCurrentLocationThunkStatus: RequestStatus;
    getCurrentLocationThunkError: Error | null;
    userPositionThunkStatus: RequestStatus;
  };
}

const initialState: UserState = {
  data: {
    zoneDetails: null,
    currentLocation: {
      accuracyInMeters: null,
      coordinates: null,
      origin: LocationOrigin.Pending,
    },
  },
  requests: {
    getCurrentLocationThunkStatus: RequestStatus.Idle,
    getCurrentLocationThunkError: null,
    userPositionThunkStatus: RequestStatus.Idle,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLocationState(state, action: PayloadAction<IUserLocation>) {
      state.data.currentLocation = action.payload;
    },
    setLocationOrigin(state, action: PayloadAction<LocationOrigin>) {
      state.data.currentLocation.origin = action.payload;
    },
    resetUserCurrentLocation(state) {
      state.data.currentLocation = initialState.data.currentLocation;
    },
    setUserZoneDetails(state, action: PayloadAction<ZoneDetails>) {
      state.data.zoneDetails = action.payload;
    },
  },

  extraReducers: builder => {
    /* User location */
    builder.addCase(updateUserLocationThunk.pending, state => {
      state.requests.userPositionThunkStatus = RequestStatus.Pending;
    });

    builder.addCase(
      updateUserLocationThunk.fulfilled,
      (state, action: PayloadAction<IUserLocation>) => {
        state.requests.userPositionThunkStatus = RequestStatus.Fulfilled;
        state.data.currentLocation.accuracyInMeters =
          action.payload.accuracyInMeters;
        state.data.currentLocation.coordinates = action.payload.coordinates;
        state.data.currentLocation.origin = action.payload.origin;
      },
    );

    builder.addCase(updateUserLocationThunk.rejected, state => {
      state.requests.userPositionThunkStatus = RequestStatus.Rejected;
    });
  },
});

export const UserActions = userSlice.actions;
