import { IExtent } from '@esri/arcgis-rest-request';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson';
import { bboxPolygon } from '@turf/turf';
import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { ReactComponent as NoAlertIcon } from 'assets/icons/no-alerts.svg';
import { ModalsContext, ModalsContextIds } from 'context';
import { isFollowZoneEnabled, isMobileDownloadEnabled } from 'flags';
import { LocalStorageFollowedZone, LocalStorageKeys } from 'types';
import { getSwAndNeFromBbox, getZoneIdFromZoneName, parseBBOX } from 'utils';
import AlertCard from '~/pages/AlertsInLocation/AlertCard';
import AlertDetailView from '~/pages/AlertsList/AlertDetailView/AlertDetailView';
import { AlertCardData } from '~/pages/AlertsList/types';
import { theme } from '~/theme';
import {
  convertGeoJsonToWkt,
  getDefaultBrowserLanguage,
  getTranslatedText,
  isZoneStatusCritical,
} from '~/utils/alertsUtils';

import { MiscZoneInfo } from './MiscZoneInfo';
import { PointsOfInterestContainer } from './PointsOfInterest';
import { ReactComponent as NoLocationFoundIcon } from '../../assets/icons/no-location-found.svg';
import {
  Color,
  MaxAllowedNumberOfZonesToFollow,
  NotFound,
} from '../../constants';
import { useIsMobileDevice } from '../../hooks';
import { I18n } from '../../i18n';
import { Card } from '../../layouts/Card';
import { Page } from '../../layouts/Page';
import {
  AlertActions,
  useAppSelector,
  useFetchZoneDetailsQuery,
  ZonesApi,
} from '../../redux-rtk';
import {
  AppActions,
  LocationSelectionType,
} from '../../redux-rtk/slices/appSlice';
import { CommonMessageCard } from '../CommonMessageCard';
import { ZoneLocationIcon } from '../Icon/ZoneLocationIcon';
import { MapPreview } from '../MapPreview';
import { Loader } from '../UI';

export const ZoneDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id: zoneId } = useParams();
  const isMobile = useIsMobileDevice();
  const { setCurrentOpenedModal } = useContext(ModalsContext);

  const [followedZones, setFollowedZones] = useLocalStorage<
    LocalStorageFollowedZone[]
  >(LocalStorageKeys.FollowedZones, []);

  const searchTerm = new URLSearchParams(useLocation().search).get(
    'searchTerm',
  );
  const centerCoords = new URLSearchParams(useLocation().search).get('coords');

  const completedAppSetupSteps = useAppSelector(
    state => state.app.data.completedAppSetupSteps,
  );

  // Query zone details if this expected to be a valid zone
  const {
    data,
    isLoading,
    isError,
    isFetching: fetchingZone,
  } = useFetchZoneDetailsQuery(zoneId, {
    skip: zoneId === NotFound,
  });

  const zone = data?.zone;

  const selectedLocation = useAppSelector(
    state => state.app.data.selectedLocation,
  );

  const hasPopup = useAppSelector(
    state => state.app.data.hasPopUp
  );

  const onZoneChosen = chosenZone => {
    navigate(`/zones/${chosenZone.identifier}`);
  };

  const handleSplitMergedZones = contextId => {
    setCurrentOpenedModal(contextId, {
      zones: data.history.children,
      type: data.history.type,
      onZoneChosen,
    });
  };

  useEffect(() => {
    if (!data || !data.history?.type) {
      return;
    }

    const formattedAlertData: any = {
      id: zone?.id,
      active: true,
      critical: isZoneStatusCritical(zone?.status),
      titles: [{ id: 'en', text: '' }],
      descriptions: [{ id: 'en', text: zone?.statusDescription }],
      counties: [{ name: zone?.countyName }],
      updatedAt: zone?.updatedAt,
      source: 'EVAC',
      statusReason: zone?.statusReason,
      statusDescription: zone?.statusDescription,
      status: { description: zone?.status, statusColor: zone?.statusColor },
      navigatedFrom: 'location',
    };

    dispatch(AlertActions.updateSelectedAlertData(formattedAlertData));

    if (data.history.type === 'split') {
      handleSplitMergedZones(ModalsContextIds.SplitSearch);
      return;
    }

    if (data.history.type === 'merged') {
      handleSplitMergedZones(ModalsContextIds.MergedSearch);
      return;
    }
  }, [data]);

  const getWktStringFromBbox = (bboxString: string) => {
    if (!bboxString) return;
    const parsedBbox = parseBBOX(bboxString);
    const BboxPolygonData = bboxPolygon(parsedBbox.flat() as BBox2d);
    return convertGeoJsonToWkt(BboxPolygonData);
  };

  useEffect(() => {
    if (zone) {
      const position = centerCoords
        ? [
            Number(centerCoords.split(',')[0]),
            Number(centerCoords.split(',')[1]),
          ]
        : [zone.latitude, zone.longitude];

      dispatch(
        AppActions[
          completedAppSetupSteps.length === 0
            ? 'setInitialLocation'
            : 'selectLocation'
        ]({
          id: zone.identifier,
          type: LocationSelectionType.Zone,
          position: position,
          details: zone,
          bbox: parseBBOX(zone.bbox),
          prevPosition:
            selectedLocation?.prevPosition ?? selectedLocation?.position,
        }),
      );
    }
  }, [zone, searchTerm]);

  const getALERTfilters = () => {
    if (!zone?.bbox) return;
    const filters: { searchGeom?: IExtent; activeOnly: boolean } = {
      activeOnly: true,
    };
    const { ne, sw } = getSwAndNeFromBbox(zone?.bbox);
    filters.searchGeom = {
      xmax: sw.lng,
      ymax: sw.lat,
      xmin: ne.lng,
      ymin: ne.lat,
    };
    return filters;
  };

  const alertResponse = ZonesApi.useFetchAlertsQuery(
    {
      pageSettings: {
        page: 0,
        size: 50,
      },
      filters: getALERTfilters(),
      sortMethod: {
        type: 'time',
        direction: 'DESC',
      },
    },
    { skip: !zone?.id && !searchTerm },
  );

  const handleViewAlertClick = alertData => {
    dispatch(
      AlertActions.updateSelectedAlertData({
        ...alertData,
        source: 'ALERT',
        navigatedFrom: 'location',
        active: true,
      }),
    );
    navigate(`/alert`);
  };

  const showEvacAlerts = !fetchingZone && zone?.status !== 'Normal';
  const showALERTalerts =
    !alertResponse?.isFetching &&
    alertResponse?.data?.content?.find(alert => alert?.source === 'ALERT');

  const handleBackClick = () => {
    if (selectedLocation?.prevPosition) {
      if (!hasPopup) {
        dispatch(
          AppActions.selectLocation({
            ...selectedLocation,
            settings: { hasPopup: true },
          }),
        );
      }
      navigate(-1);
    } else {
      navigate('/search');
    }
  };

  return (
    <Page hasBackButton onBackButtonPress={handleBackClick}>
      {isError || (!isLoading && !zone) ? (
        <>
          {searchTerm && (
            <Card
              backgroundColor="grey"
              hasShadow={false}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                width: 'fit-content',
                mb: 3,
              }}
            >
              <ZoneLocationIcon role="img" aria-hidden="true" />
              <Typography variant="body2">{searchTerm}</Typography>
            </Card>
          )}
          <Card hasShadow={false} backgroundColor="grey">
            <Typography
              variant="h2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                my: 1.5,
              }}
            >
              <NoLocationFoundIcon
                width="36px"
                height="36px"
                role="img"
                aria-hidden="true"
              />
              {I18n.t('search.noZoneFound')}
            </Typography>
            <Typography variant="body2">
              {zoneId === NotFound
                ? I18n.t('search.notFound', {
                    appName: I18n.t('common.appName'),
                  })
                : I18n.t('search.invalidZoneId')}
            </Typography>
          </Card>
        </>
      ) : isLoading || fetchingZone || alertResponse?.isFetching ? (
        <Loader />
      ) : (
        <Box
          sx={{
            gap: 3,
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
          }}
        >
          {isMobile && isFollowZoneEnabled && (
            <FormControlLabel
              value="start"
              sx={{ position: 'absolute', top: -4, right: -10 }}
              componentsProps={{
                typography: {
                  variant: 'body2',
                  color: 'text.secondary',
                },
              }}
              control={
                <Switch
                  sx={{ mr: 2 }}
                  color="primary"
                  checked={!!followedZones.find(el => el.zoneId === zoneId)}
                  onChange={(e, checked) => {
                    if (!checked) {
                      return setFollowedZones(
                        followedZones.filter(el => el.zoneId !== zoneId),
                      );
                    }

                    if (
                      followedZones.length === MaxAllowedNumberOfZonesToFollow
                    ) {
                      return enqueueSnackbar(
                        I18n.t('followZoneModal.maxFollowedZonesReached', {
                          number: MaxAllowedNumberOfZonesToFollow,
                        }),
                        {
                          variant: 'error',
                        },
                      );
                    }

                    return setCurrentOpenedModal(ModalsContextIds.FollowZone, {
                      zoneId,
                    });
                  }}
                  inputProps={{ 'aria-label': 'Follow or unfollow zone' }}
                />
              }
              labelPlacement="start"
              label={
                !followedZones.find(el => el.zoneId === zoneId)
                  ? I18n.t('zoneDetails.followZone')
                  : I18n.t('zoneDetails.followingZone')
              }
            />
          )}

          {searchTerm && (
            <Card
              backgroundColor="grey"
              hasShadow={false}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(1),
                }}
              >
                <ZoneLocationIcon role="img" aria-hidden="true" />
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 'bold' }}
                  textTransform="uppercase"
                >
                  {I18n.t('myZones.selectedZone')}
                </Typography>
              </Box>
              <Typography variant="body2">
                <b>{I18n.t('myZones.zoneId')}: </b>
                {getZoneIdFromZoneName(zone.identifier)}
              </Typography>
            </Card>
          )}
          {zone?.id && !searchTerm && <AlertDetailView />}

          {!showEvacAlerts && !showALERTalerts && (
            <CommonMessageCard
              title={I18n.t('search.noAlerts')}
              body={I18n.t('search.noActiveIncidents')}
              icon={<NoAlertIcon aria-hidden="true" role="img" />}
            />
          )}

          {searchTerm && (
            <>
              {showEvacAlerts && (
                <AlertCard
                  zoneId={zone?.id}
                  statusReason={data?.zone?.statusReason}
                  status={data?.zone?.status}
                  clickOnView={() => {
                    navigate(`/zones/${zone?.identifier}`);
                  }}
                  statusColor={data?.zone?.statusColor}
                />
              )}
              {showALERTalerts &&
                (alertResponse?.data?.content as AlertCardData[])
                  ?.filter(alert => alert.source === 'ALERT')
                  .map(alert => {
                    return (
                      <AlertCard
                        key={alert?.id}
                        status={getTranslatedText(
                          alert?.titles,
                          getDefaultBrowserLanguage(),
                        )}
                        statusReason={getTranslatedText(
                          alert?.descriptions,
                          getDefaultBrowserLanguage(),
                        )}
                        clickOnView={() => handleViewAlertClick(alert)}
                        statusColor={Color.UniformBlue}
                      />
                    );
                  })}
            </>
          )}
          <MapPreview mapId="zoneDetailsPreview" />

          <PointsOfInterestContainer zoneId={zoneId} />

          <MiscZoneInfo zone={zone} />
        </Box>
      )}
    </Page>
  );
};
