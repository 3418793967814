import { Box, Button, Typography } from '@mui/material';

import Icon from '~/components/Icon';
import SwitchComponent from '~/components/SwitchComponent';
import { I18n } from '~/i18n';
import { useAppDispatch, useAppSelector } from '~/redux-rtk/hooks';
import { AlertActions, CalendarMenu } from '~/redux-rtk/slices/alertSlice';
import { theme } from '~/theme';

import DateSelector from './Calendar/DateSelector';
import SearchZoneId from './SearchZoneId';
import StatusSelector from './StatusSelector';

export default function ExpiredAlertFilters() {
  const dispatch = useAppDispatch();
  const alertCritical = useAppSelector(state => state.alert.alertCritical);
  const filters = useAppSelector(state => state.alert.expiredAlertFilters);
  const toggleCriticalFilter = () => {
    dispatch(AlertActions.changeAlertCritical(!alertCritical));
  };
  const clearFilters = () => {
    dispatch(AlertActions.resetExpiredAlertFilters());
  };
  const filterSelected =
    filters.zoneId ||
    filters.zoneStatus > 0 ||
    filters.time?.selectedMenu !== CalendarMenu.all;
  return (
    <Box
      sx={{
        display: 'flex',
        gap: `${theme.spacing(1)} ${theme.spacing(5)}`,
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <SearchZoneId />
      <StatusSelector />
      <DateSelector />
      {filterSelected && (
        <Button
          onClick={() => clearFilters()}
          startIcon={<Icon name="Close" color={theme.palette.primary.main} />}
          size="small"
          sx={{
            marginLeft: theme.spacing(-3),
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.secondary.main,
            '&:hover': {
              backgroundColor: 'transparent',
            },
            marginTop: theme.spacing(2),
          }}
        >
          <Typography
            variant="caption"
            style={{ color: theme.palette.primary.main, fontWeight: 600 }}
          >
            {I18n.t('alertsList.clear')}
          </Typography>
        </Button>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          color: theme.palette.secondary.main,
          marginLeft: theme.spacing(7),
        }}
      >
        <Typography variant="body2">
          {I18n.t('alertsList.onlyCritical')}
        </Typography>
        <SwitchComponent
          checked={alertCritical}
          onChange={toggleCriticalFilter}
        />
      </Box>
    </Box>
  );
}
