import { Typography } from '@mui/material';
import { Card } from '../../layouts/Card';
import { PrimaryButton } from '../common/Buttons/PrimaryButton';

interface Props {
  title: string;
  body: string;
  buttonLabel?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}

export const CommonMessageCard = ({ title, body, buttonLabel, icon, onClick }: Props) => {
  return (
    <Card>
      <Typography
        variant="h3"
        sx={{
          gap: 1.5,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {icon}
        {title}
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        {body}
      </Typography>
      {onClick &&
        <PrimaryButton
          onClick={onClick}
          sx={{ width: '100%', mt: 2 }}
          label={buttonLabel}
        />
      }
    </Card>
  )
}

