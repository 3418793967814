import { useLocation, useNavigate } from 'react-router-dom';
import { Fragment } from 'react';
import { BackButton } from '~/components/common/Buttons';
import { Map } from '~/components/Map';

export const FullscreenMap = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const hideBackButton = new URLSearchParams(location.search).get(
    'hideBackButton',
  );

  return (
    <Fragment>
      {!hideBackButton && (
        <BackButton
          onClick={() => navigate(-1)}
          sx={{
            position: 'absolute',
            zIndex: 3,
            top: 16,
            left: 16,
            boxShadow: 1,
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: 'grey.50',
            },
          }}
        />
      )}
      <Map mapId='fullScreenMap' sx={{ height: '100vh', width: '100%' }} key={location.pathname} />
    </Fragment>
  );
};
