import { Box, Typography } from '@mui/material';
import React from 'react';
import { ZoneLocationIcon } from '~/components/Icon/ZoneLocationIcon';
import { I18n } from '~/i18n';
import { Card } from '~/layouts/Card';
import { theme } from '~/theme';
import { getZoneIdFromZoneName } from '~/utils/mapUtils';
import { Zone } from '../../types';

type props = {
  zoneId: string;
  bgColorEnabled?: boolean;
};
function SelectedZoneCard({ zoneId, bgColorEnabled }: props) {
  return (
    <Card
      backgroundColor={bgColorEnabled ? 'grey' : 'white'}
      hasShadow={false}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
        }}
      >
        <ZoneLocationIcon role="img" aria-hidden="true" />
        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold' }}
          textTransform="uppercase"
        >
          {I18n.t('myZones.selectedZone')}
        </Typography>
      </Box>
      <Typography variant="body2">
        <b>{I18n.t('myZones.zoneId')}: </b>
        {getZoneIdFromZoneName(zoneId)}
      </Typography>
    </Card>
  );
}

export default SelectedZoneCard;
