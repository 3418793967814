import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { polygon } from '@turf/helpers';
import { AlertCardData } from '~/pages/AlertsList/types';
import {
  filterResponseData,
  getBufferedPointForQuery,
} from '~/utils/alertsUtils';

export const gpAdminApi = createApi({
  reducerPath: 'gpAdminApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_GP_ADMIN_URL,
  }),
  endpoints: builder => ({
    fetchAlertsWithCoordinates: builder.query<any, any>({
      async queryFn(location: [number, number], api, extraOptions, baseQuery) {
        const response = await baseQuery({
          url: `/api/alertslist/activegpalertsList`,
          method: 'POST',
          body: { geos: [getBufferedPointForQuery(location)] },
        });

        // Filter out responses that don't actually match the initial query point, and return
        return {
          data: filterResponseData(
            response?.data as AlertCardData[],
            polygon([
              [
                [location[0], location[1]],
                [location[0], location[1]],
                [location[0], location[1]],
                [location[0], location[1]],
                [location[0], location[1]],
              ],
            ]),
          ),
        };
      },
    }),
  }),
});
export const { useFetchAlertsWithCoordinatesQuery } = gpAdminApi;
