import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { trackUserUnfollowedZone } from '~/analytics/fns';
import { MaxAllowedNumberOfZonesToFollow, NotFound } from '~/constants';
import { ModalsContext, ModalsContextIds } from '~/context';
import { I18n } from '~/i18n';
import {
  useAppSelector,
  useFetchZoneDetailsQuery,
  useFetchZoneDetailsWithCoordsQuery,
} from '~/redux-rtk';
import {
  LocalStorageFollowedZone,
  LocalStorageKeys,
  LocationOrigin,
} from '~/types';
import { PropsWithSx, getZoneIdFromZoneName } from '~/utils';
import { AsyncMapCard } from '../AsyncMapCard';
import {
  convertWktToGeoJson,
  getAlertColor,
  getDefaultBrowserLanguage,
  getTranslatedText,
} from '~/utils/alertsUtils';
import { Box, Tooltip, Typography } from '@mui/material';
import Icon from '~/components/Icon';
import { AlertCardData } from '~/pages/AlertsList/types';
import { Point } from '@turf/turf';

interface AlertCardProps {
  sx?: any;
  onClose?: () => void;
  alertDetails: AlertCardData;
}

export const AlertCardPopup = ({
  sx,
  onClose,
  alertDetails,
}: PropsWithSx<AlertCardProps>) => {
  const getAlertStatus = () => {
    return alertDetails?.statusReason
      ? alertDetails?.statusReason
      : getTranslatedText(alertDetails?.titles, getDefaultBrowserLanguage());
  };

  const getAlertDescription = () => {
    return alertDetails?.statusDescription
      ? alertDetails.statusDescription
      : getTranslatedText(
          alertDetails?.descriptions,
          getDefaultBrowserLanguage(),
        );
  };

  return (
    <AsyncMapCard
      onClose={() => {
        onClose();
      }}
      closeButtonLabel={`Close Zone ${alertDetails?.status}`}
      sx={{ ...sx }}
    >
      <Box
        sx={{
          display: 'flex',
          borderRadius: 1,
          flexDirection: 'row',
        }}
      >
        <Icon
          size={20}
          color={getAlertColor(alertDetails)}
          name={'StatusDot'}
        />

        <Box
          sx={{
            pl: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography variant="body1" fontWeight={'600'} noWrap>
            {getAlertStatus()}
          </Typography>
          <Tooltip title={getAlertDescription()}>
            <Typography
              variant="body2"
              sx={{
                marginTop: '3px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {getAlertDescription()}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
    </AsyncMapCard>
  );
};
