import { Box, SxProps, Typography, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

import { getAddressFromLocation } from '~/api';
import Icon from '~/components/Icon';
import { Loader } from '~/components/Loader';
import { Color } from '~/constants';
import { useAppSelector } from '~/redux-rtk/';
import { LocationSelectionType } from '~/redux-rtk/slices/appSlice';
import { theme } from '~/theme';
import { getZoneIdFromZoneName, PropsWithSx } from '~/utils';

import { I18n } from '../../i18n/i18n';
import { BaseMapCard } from '../Map/MapCards/BaseMapCard';

interface Props {
  zoneId?: number | string;
  currentLocation?: boolean;
  showHeader?: boolean;
  currentLocationIcon?: boolean;
  sx?: SxProps;
  greyBackGround?: boolean;
  onClose?: ()=> void;
  minHeight?: number;
}

const includedLocationTypes = [
  LocationSelectionType.Position,
  LocationSelectionType.Zone,
  LocationSelectionType.Alert,
];

export const SelectedLocation = ({
  zoneId,
  currentLocation,
  sx,
  showHeader,
  currentLocationIcon,
  greyBackGround,
  onClose,
  minHeight
}: PropsWithSx<Props>) => {
  const selectedLocation = useAppSelector(
    state => state.app.data.selectedLocation,
  );

  const userLocation = useAppSelector(state => state.user.data.currentLocation);
  const [loading, setLoading] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState('');
  useEffect(() => {
    if (
      !userLocation?.coordinates ||
      (!includedLocationTypes.includes(selectedLocation?.type) &&
        !currentLocation)
    ) {
      return;
    }
    setLoading(true);
    const locationCoordinates = currentLocation
      ? (userLocation.coordinates as [number, number])
      : (selectedLocation?.prevPosition as [number, number]) ??
        (selectedLocation?.position as [number, number]);

    const locationZoom = selectedLocation?.zoom;

    getAddressFromLocation(locationCoordinates, locationZoom)
      .then(response => {
        setLoading(false);
        if (response?.address) {
          setSelectedAddress(response.address?.LongLabel);
        }
      })
      .catch(err => setLoading(false));
  }, [selectedLocation, userLocation]);
  return (<BaseMapCard
    minHeight={minHeight}
    Icon={
      <Icon
        name={currentLocationIcon ? 'Location' : 'LocationShow'}
        size={25}
        color={Color.DarkBlue}
      ></Icon>
    }
    iconTitle={currentLocation ? I18n.t('myZones.myCurrentLocation') : I18n.t('myZones.selectedLocation')}
    onClose={onClose}
    isCloseButtonVisible={Boolean(onClose)}
    sx={
      greyBackGround
        ? {
          backgroundColor: Color.Grey50,
          borderRadius: theme.spacing(1),
          margin: theme.spacing(0, 0, 2, 0),
          padding: theme.spacing(1, 2),
        }
        : {
          padding: theme.spacing(1, 2),
        }
    }
    closeButtonLabel="Close"
    isFetching={loading}
  >

    <Tooltip title={selectedAddress}>
      <Typography
        variant="body1"
        sx={{ marginLeft: '.3em', maxWidth: theme.spacing(40) }}
      >
        {selectedAddress}
      </Typography>
    </Tooltip>

    {zoneId && (
      <Typography variant="body1" sx={{ marginLeft: '.3em' }}>
        <b>{I18n.t('myZones.zoneId')}</b>:{' '}
        {getZoneIdFromZoneName(String(zoneId))}
      </Typography>
    )}
  </BaseMapCard>
  );
};
