import { Card, CardProps, Fab } from "@mui/material";
import { PropsWithChildren } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useIsMobileDevice } from "../../hooks";
import { I18n } from "../../i18n";
import { PropsWithSx } from "../../utils/sxUtils";
import { Map } from "../Map/Map";

type props = {
  mapId: string
};
export const MapPreview = ({
  sx,
  mapId
}: PropsWithChildren<PropsWithSx<CardProps & props>>) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobileDevice("lg");

  if (!isMobile) {
    return null;
  }

  return (
    <Card
      sx={{
        borderRadius: 2,
        overflow: "hidden",
        height: "380px",
        position: "relative",
        ...sx,
      }}
    >
      <Map
        sx={{
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          bottom: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: 2,
          position: "absolute",
          pointerEvents: "none",
        }}
        hasPopups={true}
        hasZoomControls={false}
        key={location.pathname}
        mapId={mapId}
      />

      <Fab
        onClick={() => navigate("/fullscreenMap")}
        size="small"
        variant="extended"
        aria-label="Full screen"
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          mt: 2,
          mr: 2,
          boxShadow: 1,
          px: 2,
          zIndex: 3,
          backgroundColor: "white",
        }}
      >
        {I18n.t("mapPreview.viewFullScreen")}
      </Fab>
    </Card>
  );
};
