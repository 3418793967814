import { Box, Typography } from '@mui/material';

import Icon from '~/components/Icon';
import { Color } from '~/constants';
import { I18n } from '~/i18n';
import { theme } from '~/theme';

import ExpiredAlertFilters from './ExpiredAlertFilters/index';
import { AlertCardData, AlertDetails } from './types';
import ExpiredAlertsTable from '../../containers/AlertsList/ExpiredAlertList/ExpiredAlertTable';
import EmptyAlerts from '~/containers/AlertsList/EmptyAlerts';
import { Loader } from '~/components/Loader';

type TabPanelExpiredProps = {
  selectedTab: string;
  alertDetails: AlertDetails;
  isLoading?: boolean;
};
const TabPanelExpired = ({
  selectedTab,
  alertDetails,
  isLoading,
}: TabPanelExpiredProps) => {
  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${selectedTab}`}
      aria-labelledby={`simple-tab-${selectedTab}`}
    >
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
          }}
        >
          <ExpiredAlertFilters />
          <Box
            sx={{
              display: 'flex',
              gap: theme.spacing(1),
            }}
          >
            <Icon name="IncidentSelected" size={14} color={Color.NeutralGrey} />
            <Typography variant="body2" fontStyle={'italic'}>
              {I18n.t('alertsList.expiredAlerts')}
            </Typography>
          </Box>
        </Box>
        {isLoading ? (
          <Loader sx={{ marginTop: theme.spacing(20) }} />
        ) : alertDetails?.content?.length > 0 ? (
          <ExpiredAlertsTable alertDetails={alertDetails} />
        ) : (
          <EmptyAlerts
            sx={{
              mt: theme.spacing(8),
            }}
          />
        )}
      </Box>
    </div>
  );
};

export default TabPanelExpired;
