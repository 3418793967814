import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon';
import { MapPreview } from '~/components/MapPreview';
import SelectComponent from '~/components/SelectComponent/SelectComponent';
import { Color } from '~/constants';
import { I18n } from '~/i18n';
import { getZoneIdFromZoneName } from '~/utils';
import {
  extractDateTime,
  getAlertColor,
  getTranslatedText,
} from '~/utils/alertsUtils';
import '~/i18n/alertsI18n';

const AlertDetails = ({ alertDetails, availableLanguages }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [date, time] = extractDateTime(alertDetails?.updatedAt);
  const shouldDisplayMapPreview = !(
    alertDetails?.navigatedFrom === 'location' &&
    alertDetails?.source === 'EVAC'
  );

  return (
    <>
      {availableLanguages?.length > 1 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            color: theme.palette.secondary.main,
            width: '164px',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>
            {I18n.t('alertsList.selectLanguageHeader')}
          </Typography>
          <SelectComponent
            options={availableLanguages}
            selectedValue={i18n.language}
            onChange={e => {
              i18n.changeLanguage(String(e.target.value));
            }}
            icon={
              <Icon
                name="World"
                size={16}
                color={theme.palette.secondary.main}
              />
            }
          />
        </Box>
      )}
      {!alertDetails?.active && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(0.5),
            margin: theme.spacing(2, 0),
          }}
        >
          <Icon name="InformationFilled" size={14} color={Color.NeutralGrey} />
          <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
            {t('alertsList.expiredAlertWarning')}
          </Typography>
        </Box>
      )}
      <Typography
        variant="h2"
        sx={{
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(0.5),
        }}
      >
        <Icon
          name={'StatusDot'}
          color={getAlertColor(alertDetails)}
          size={20}
        />
        {alertDetails?.source === 'EVAC'
          ? alertDetails?.status?.description
          : getTranslatedText(alertDetails?.titles, i18n.language)}
      </Typography>
      {alertDetails?.updatedAt && (
        <Box
          sx={{
            display: 'flex',
            gap: theme.spacing(2),
            margin: theme.spacing(1, 3),
          }}
        >
          <Box sx={{ display: 'flex', gap: theme.spacing(1) }}>
            <Typography variant="body1" sx={{ color: Color.NeutralGrey }}>
              {t('alertsList.headerTexts.date')}
            </Typography>
            <Typography variant="body1">{date}</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: theme.spacing(1) }}>
            <Typography variant="body1" sx={{ color: Color.NeutralGrey }}>
              {t('alertsList.headerTexts.time')}
            </Typography>
            <Typography variant="body1">{time}</Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          margin: theme.spacing(2, 0),
          backgroundColor: theme.palette.grey[50],
          borderRadius: theme.spacing(1.5),
        }}
      >
        {alertDetails?.statusReason && (
          <Box
            sx={{
              padding: theme.spacing(2),
            }}
          >
            <Typography variant="h3" sx={{ marginBottom: theme.spacing(2) }}>
              {t('alertsList.headerTexts.reason')}
            </Typography>
            <Typography variant="body1">
              {alertDetails?.statusReason}
            </Typography>
          </Box>
        )}
        {alertDetails?.descriptions.length > 0 && (
          <Box
            sx={{
              padding: theme.spacing(2),
            }}
          >
            <Typography variant="h3" sx={{ marginBottom: theme.spacing(2) }}>
              {t('alertsList.headerTexts.description')}
            </Typography>
            <Typography variant="body1">
              {getTranslatedText(alertDetails?.descriptions, i18n.language)}
            </Typography>
          </Box>
        )}
      </Box>
      {shouldDisplayMapPreview && <MapPreview mapId='alertDetailsPreview'/>}
      {alertDetails?.zonesImpacted?.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.grey[50],
            padding: theme.spacing(2),
            margin: theme.spacing(1, 0),
            gap: theme.spacing(2),
            borderRadius: theme.spacing(1.5),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(1),
              }}
            >
              <Icon
                name="Warning"
                size={18}
                color={theme.palette.secondary.main}
              />
              <Typography variant="h3" sx={{ fontSize: '14px' }}>
                {t('alertsList.headerTexts.zonesAffected')}
              </Typography>
            </Box>
            <Typography variant="body1">
              {alertDetails?.zonesImpacted?.length}
            </Typography>
          </Box>
          {alertDetails?.zonesImpacted?.map(zone => (
            <Box
              sx={{
                borderRadius: theme.spacing(1),
                padding: theme.spacing(0.5, 1),
                gap: theme.spacing(1),
                backgroundColor: theme.palette.common.white,
              }}
            >
              <Typography variant="body1">
                {getZoneIdFromZoneName(zone)}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
      {alertDetails?.critical && (
        <Box
          sx={{
            backgroundColor: theme.palette.grey[50],
            padding: theme.spacing(2),
            margin: theme.spacing(1, 0),
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: theme.spacing(1.5),
          }}
        >
          <Box sx={{ display: 'flex', gap: theme.spacing(1) }}>
            <Icon name="Flag" size={20} color={theme.palette.secondary.main} />
            <Typography variant="h3" sx={{ fontSize: '14px' }}>
              {t('alertsList.headerTexts.severity')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '4px 8px',
              borderRadius: theme.spacing(3),
              gap: theme.spacing(0.5),
              background: Color.WhiteSmoke,
              border: `1px solid ${Color.PaleRed}`,
            }}
          >
            <Icon name="Warning" size={12} color={Color.DarkRed} />
            <Typography
              sx={{ fontSize: 12, fontWeight: 600, color: Color.DarkRed }}
            >
              {t('alertsList.headerTexts.critical')}
            </Typography>
          </Box>
        </Box>
      )}
      {alertDetails?.counties.length > 0 && (
        <Box
          sx={{
            backgroundColor: theme.palette.grey[50],
            padding: theme.spacing(2),
            margin: theme.spacing(2, 0),
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            borderRadius: theme.spacing(1.5),
          }}
        >
          <Box sx={{ display: 'flex', gap: theme.spacing(1) }}>
            <Icon
              name="County"
              size={20}
              color={theme.palette.secondary.main}
            />
            <Typography variant="h3" sx={{ fontSize: '14px' }}>
              {t('alertsList.headerTexts.county')}
            </Typography>
          </Box>
          {alertDetails?.counties?.length > 1 ? (
            <Box sx={{ width: '100%', margin: theme.spacing(2, 0) }}>
              <Typography variant="body1">
                {alertDetails?.counties.map(county => {
                  return `${county.name} , `;
                })}
              </Typography>
            </Box>
          ) : (
            <Typography variant="body1">
              {alertDetails?.counties[0]?.name}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default AlertDetails;
