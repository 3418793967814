import {
  bbox,
  booleanDisjoint,
  buffer,
  distance,
  Feature,
  point,
  polygon,
  truncate,
  union,
} from '@turf/turf';
import { MultiPolygon, Point, Polygon } from 'geojson';
import { parse, stringify } from 'wellknown';

import { Color } from '~/constants';
import { zoneStatusData } from '~/constants/zoneStatus';
import { AlertCardData, AlertGroup } from '~/pages/AlertsList/types';
import { CalendarMenu, TimeFilter } from '~/redux-rtk/slices/alertSlice';
import { mergeAndRemoveDuplicate, round } from '~/utils';

// The distance (in meters) that we buffer our search polygon
const QUERY_BUFFER_M = 1000;

export const extractDateTime = (isoString: string) => {
  const date = new Date(isoString);

  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const year = date.getUTCFullYear();
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Format the date and time
  const formattedDate = `${month}-${day}-${year}`;
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  return [formattedDate, formattedTime];
};

export const getNativeLanguageNames = languageCodes => {
  return languageCodes.map(code => {
    const displayNames = new Intl.DisplayNames([code], { type: 'language' });
    return {
      languageCode: code,
      displayName: displayNames.of(code),
    };
  });
};

export const getTranslatedText = (alertData, currentLanguage) => {
  if (alertData) {
    return (
      alertData?.find(data => data.id === currentLanguage)?.text ||
      alertData?.find(data => data.id === 'en')?.text
    );
  }
  return null;
};

export const getDefaultBrowserLanguage = () => {
  return (
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    'en'
  );
};

export const getAlertColor = (alertData: AlertCardData) => {
  if (!alertData?.active) {
    return Color.Grey600;
  }
  if (alertData?.source === 'EVAC') {
    return (
      zoneStatusData[alertData?.status?.id]?.color ??
      alertData?.status?.statusColor
    );
  }
  return Color.UniformBlue;
};

export const convertWktToGeoJson = wkt => {
  try {
    return parse(wkt);
  } catch (error) {
    return null;
  }
};

export const convertGeoJsonToWkt = geoJson => {
  try {
    return stringify(geoJson);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getDateFilters = (timeFilter: TimeFilter) => {
  switch (timeFilter.selectedMenu) {
    case CalendarMenu.today:
      return [
        {
          property: 'time',
          operator: 'eq',
          value: timeFilter?.fromDate,
        },
      ];
    case CalendarMenu.lastSevenDays:
      return [
        {
          property: 'time',
          operator: 'gte',
          value: timeFilter?.fromDate,
        },
      ];
    case CalendarMenu.lastThirtyDays:
      return [
        {
          property: 'time',
          operator: 'gte',
          value: timeFilter?.fromDate,
        },
      ];
    case CalendarMenu.personalisedPeriod:
      return [
        {
          property: 'time',
          operator: 'gte',
          value: timeFilter?.fromDate,
        },
        {
          property: 'time',
          operator: 'lte',
          value: timeFilter?.toDate,
        },
      ];
  }
};

export const isZoneStatusCritical = status => {
  const statusObject = Object.values(zoneStatusData).find(
    item => item.status === status,
  );
  return statusObject?.critical ?? false;
};

// Create a buffered point to query with, to maximize caching
export const getBufferedPointForQuery = (location: [number, number]): string =>
  // We transform the search geometry in several ways:
  // 1. Round the lat / lon to the nearest 3 decimal places
  // 2. Buffer that point to 1000 meters (which is a safe amount more than the distance represented
  //    by 3 decimal places of coordinates)
  // 3. Convert that geojson to wkt
  convertGeoJsonToWkt(
    buffer(point([round(location[0]), round(location[1])]), QUERY_BUFFER_M, {
      units: 'meters',
    }),
  );

// We transform the search geometry in several ways:
// 1. Convert the rounded points to a polygon
// 2. Buffer that polygon
// 3. Truncate the precision to 3 decimal places
// 4. Convert that geojson to wkt
export const getBufferedPolygonForQuery = (extent: {
  xmax: number;
  ymax: number;
  xmin: number;
  ymin: number;
}) =>
  convertGeoJsonToWkt(
    truncate(
      buffer(
        polygon([
          [
            [round(extent.xmax), round(extent.ymax)],
            [round(extent.xmax), round(extent.ymin)],
            [round(extent.xmin), round(extent.ymin)],
            [round(extent.xmin), round(extent.ymax)],
            [round(extent.xmax), round(extent.ymax)],
          ],
        ]),
        QUERY_BUFFER_M,
        { units: 'meters' },
      ),
      { coordinates: 2, precision: 3 },
    ),
  );

export const reSortResponseData = (
  data: AlertCardData[],
  initialQueryLocation: [number, number],
) => {
  // Create a point to sort on after the query
  const sortPoint = point(initialQueryLocation);
  // Return the data re-sorted based on the initial location
  return data?.sort?.(
    (a, b) =>
      distance(convertWktToGeoJson(a.centroid) as Point, sortPoint) -
      distance(convertWktToGeoJson(b.centroid) as Point, sortPoint),
  );
};

export const filterResponseData = (
  responseData: AlertCardData[],
  filterGeom: Feature<Polygon | Point>,
) => {
  return responseData?.filter(
    // Use the initial, unrounded, unbuffered search point as a polygon to filter on
    alert =>
      // booleanIntersects doesn't seem to be available, but disjoint is the exact opposite:
      // https://turfjs.org/docs/6.5.0/api/booleanDisjoint
      !booleanDisjoint(
        filterGeom,
        convertWktToGeoJson(alert.geom) as MultiPolygon,
      ),
  );
};

export const transformToAlertCardData = (
  alertGroup: AlertGroup,
): AlertCardData => {
  const groupedAlerts = alertGroup?.alerts?.reduce(
    (
      prevAlerts: { geom: MultiPolygon; zonesImpacted: string[] },
      currentAlert,
    ) => {
      if (!prevAlerts)
        return {
          zonesImpacted: currentAlert.zonesImpacted,
        };
      return {
        zonesImpacted: mergeAndRemoveDuplicate(
          prevAlerts.zonesImpacted,
          currentAlert.zonesImpacted,
        ),
      };
    },
    null,
  );

  //we just need zonesImpacted to hihglight all the zones in a group
  //and rest of the data is taken from the first alert in the group
  return {
    ...alertGroup?.alerts[0],
    id: alertGroup?.id_event_incident,
    zonesImpacted: groupedAlerts.zonesImpacted,
  };
};

export const getBboxSquareAroundCoordinate = ({
  coordinate,
  bufferInKm,
}: {
  coordinate: { lat: number; lon: number };
  bufferInKm: number;
}): [number, number, number, number] => {
  // Create a Turf point
  const locationCoordinates = point([coordinate.lat, coordinate.lon]);

  // Create a buffer around the point
  const buffered = buffer(locationCoordinates, bufferInKm, {
    units: 'kilometers',
  });

  // Get the bounding box of the buffered area
  return bbox(buffered) as [number, number, number, number];
};

export const filterEvacAlertsByGeometry = (
  responseData,
  filterGeom: Feature<Polygon | Point>,
) => {
  return responseData?.filter(
    // Use the initial, unrounded, unbuffered search point as a polygon to filter on
    alert =>
      // booleanIntersects doesn't seem to be available, but disjoint is the exact opposite:
      // https://turfjs.org/docs/6.5.0/api/booleanDisjoint
      !booleanDisjoint(filterGeom, JSON.parse(alert.zonegeom)),
  );
};
