import { Box, Collapse, Typography } from '@mui/material';
import parse from 'html-react-parser';
import { MapDataEvent, MapGeoJSONFeature } from 'maplibre-gl';
import { useEffect, useMemo, useState } from 'react';
import { useMap } from 'react-map-gl';
import { AnimalShelterIcon } from '~/components/Icon/AnimalShelterIcon';
import { EvacuationCenterIcon } from '~/components/Icon/EvacuationCenterIcon';
import { GenericArrivalPointIcon } from '~/components/Icon/GenericArrivalPointIcon';
import { RoadClosureIcon as RoadClosureSvg } from '~/components/Icon/RoadClosureIcon';
import { TrafficControlPointIcon as TrafficControlPointSvg } from '~/components/Icon/TrafficControlPointIcon';
import { LocationSelectionType } from '~/redux-rtk/slices/appSlice';

import { I18n } from '../../../../i18n';
import { PropsWithSx } from '../../../../utils';
import {
  ArrivalPointId,
  getPointOfInterestType,
  TrafficControlPointId,
} from '../../config';
import { ArrivalPoint, RoadClosure } from '../../types';
import { BaseMapCard } from '../BaseMapCard';

interface Props {
  data: RoadClosure | ArrivalPoint;
  onClose: () => void;
  isArrivalPoint?: boolean;
}

// A generic card handling arrival points and traffic control points
export const ApTcpCard = ({
  sx,
  data,
  onClose,
  isArrivalPoint = false,
}: PropsWithSx<Props>) => {
  const { current: map } = useMap();
  // Calculate the icon based on the type
  const icon = useMemo(
    () =>
      isArrivalPoint ? (
        data.type === LocationSelectionType.AnimalShelter ? (
          <AnimalShelterIcon htmlColor="black" />
        ) : data.type === LocationSelectionType.EvacuationCenter ? (
          <EvacuationCenterIcon htmlColor="black" />
        ) : (
          <GenericArrivalPointIcon htmlColor="black" />
        )
      ) : data.type === LocationSelectionType.TrafficControlPoint ? (
        <TrafficControlPointSvg htmlColor="black"/>
      ) : (
        <RoadClosureSvg htmlColor="black" />
      ),
    [data.type, isArrivalPoint],
  );

  const sections = useMemo(
    () =>
      isArrivalPoint
        ? [
            {
              title: (data as ArrivalPoint)?.name,
              content: data.address,
            },
          ]
        : [{ title: I18n.t('map.name'), content: data.address }],
    [isArrivalPoint, data.address],
  );

  const title = I18n.t(`map.${data.type}`);

  return (
    <BaseMapCard
      title={title}
      Icon={icon}
      iconTitle={(data as ArrivalPoint).name ?? title}
      iconBackgroundColor={isArrivalPoint ? 'primary.main' : 'danger.main'}
      onClose={onClose}
      sx={{ minHeight: 120, minWidth: 270, ...sx }}
      closeButtonLabel={`Close ${title} ${
        (data as ArrivalPoint).name ?? data.address
      }`}
      sections={sections}
    >
      <Collapse in={(data as RoadClosure).description?.length > 0} exit={false}>
        <Box sx={{ mt: 1 }}>
          <Typography variant="caption">{I18n.t('map.description')}</Typography>

          <Typography
            variant="body1"
            component="span"
            sx={{
              fontFamily: 'Roboto',
              color: 'text.primary',
              '& p': {
                margin: 0,
                mt: 0.25,
              },
            }}
          >
            {(data as RoadClosure).description &&
              parse((data as RoadClosure).description)}
          </Typography>
        </Box>
      </Collapse>
    </BaseMapCard>
  );
};
