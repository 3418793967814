import { Color } from '~/constants';
import {
    AlertActions,
    useAppDispatch,
} from '~/redux-rtk';
import {
    getDefaultBrowserLanguage,
    getTranslatedText,
} from '~/utils/alertsUtils';

import AlertCard from './AlertCard';
import { useNavigate } from 'react-router-dom';
type Props = {
    evacDetails?: any;
    alertDetails?: any;
};
export const CommonAlertCard = ({ evacDetails, alertDetails }: Props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleViewAlertClick = alertData => {
        dispatch(
            AlertActions.updateSelectedAlertData({
                ...alertData,
                source: 'ALERT',
                navigatedFrom: 'location',
                active: true,
            }),
        );
        navigate(`/alert`);
    };
    return (<>
        {evacDetails?.shouldShowEvacData && (
            <AlertCard
                zoneId={evacDetails?.zoneId}
                statusReason={evacDetails?.zone?.statusReason}
                status={evacDetails?.zone?.status}
                clickOnView={() => {
                    navigate(`/zones/${evacDetails?.zoneId}`);
                }}
                statusColor={evacDetails?.zone?.statusColor}
            />
        )}
        {alertDetails?.shouldShowAlertData &&
            alertDetails?.data?.content?.map(alert => {
                return (
                    <AlertCard
                        key={alert?.id}
                        status={getTranslatedText(
                            alert?.titles,
                            getDefaultBrowserLanguage(),
                        )}
                        statusReason={getTranslatedText(
                            alert?.descriptions,
                            getDefaultBrowserLanguage(),
                        )}
                        clickOnView={() => handleViewAlertClick(alert)}
                        statusColor={Color.UniformBlue}
                    />
                );
            })}
    </>);
};
