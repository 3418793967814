import { Box, Pagination, styled } from '@mui/material';
import { FC } from 'react';

import { Color } from '~/constants';
import { AlertDetails } from '~/pages/AlertsList/types';
import { useAppDispatch, useAppSelector } from '~/redux-rtk/hooks';
import { AlertActions } from '~/redux-rtk/slices/alertSlice';

import ActiveAlertsCard from '../ActiveAlertsCard';

// to be removed if pagination is not required
const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    borderRadius: theme.spacing(1),
  },
  '& .Mui-selected': {
    backgroundColor: Color.White,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: Color.White,
    },
  },
}));
interface ActiveAlertListProps {
  alertDetails: AlertDetails;
}

const ActiveAlertList: FC<ActiveAlertListProps> = ({ alertDetails }) => {
  const dispatch = useAppDispatch();
  const handleChange = (event, value) => {
    dispatch(AlertActions.updateAlertPageSelected(value - 1));
  };
  const alertPageSelected = useAppSelector(
    state => state.alert.alertPageSelected,
  );

  return (
    <Box>
      {alertDetails?.content?.map(card => {
        return (
          <ActiveAlertsCard
            key={card.id}
            alertData={card}
            sx={{
              borderBottom: `1px solid ${Color.AntiFlashWhite}`,
              borderRadius: 0,
            }}
          />
        );
      })}
      <Box mt={2} display="flex" justifyContent="center">
        <StyledPagination
          count={Math.ceil(alertDetails?.totalPages)}
          page={alertPageSelected + 1}
          onChange={handleChange}
        />
      </Box>
    </Box>
  );
};

export default ActiveAlertList;
