import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';
import { union } from 'lodash';

import { updateUserLocationThunk } from 'redux-rtk/thunks';
import { appMapConfig } from '~/components/Map/config';
import {
  ArrivalPoint,
  EvacuationCenter,
  Hazard,
  RoadClosure,
  Zone,
} from '~/components/Map/types';
import { AlertCardData } from '~/pages/AlertsList/types';

export enum AlertsSortMethod {
  Latest = 'latest',
  Nearest = 'nearest',
  County = 'county',
}

interface ZoneBasicDetails {
  status: string;
}

export enum CalendarMenu {
  all = 'All',
  today = 'Today',
  lastSevenDays = 'Last 7 days',
  lastThirtyDays = 'Last 30 days',
  personalisedPeriod = 'Personalised period',
}

export interface TimeFilter {
  selectedMenu: CalendarMenu;
  fromDate: string;
  toDate?: string;
}

export interface ExpiredAlertFilters {
  zoneId?: string;
  zoneStatus?: number;
  time?: TimeFilter;
}

export enum AlertStatusType {
  Active = 'active',
  Expired = 'expired',
}

export interface AlertState {
  selectedAlertSort: AlertsSortMethod;
  alertCritical: boolean;
  alertSearchTerm: string;
  alertStatus: AlertStatusType;
  alertPageSelected: number;
  selectedAlertData: AlertCardData;
  expiredAlertFilters: ExpiredAlertFilters;
}

const calendarDefaultSettings = {
  fromDate: '',
  toDate: '',
  selectedMenu: CalendarMenu.all,
};

const initialState: AlertState = {
  selectedAlertSort: AlertsSortMethod.Latest,
  alertCritical: false,
  alertSearchTerm: '',
  alertStatus: AlertStatusType.Active,
  alertPageSelected: 0,
  selectedAlertData: null,
  expiredAlertFilters: {
    zoneId: '',
    zoneStatus: 0,
    time: { ...calendarDefaultSettings },
  },
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    selectAlertSort(state, action: PayloadAction<AlertsSortMethod>) {
      state.selectedAlertSort = action.payload;
    },
    updateAlertSearchTerm(state, action: PayloadAction<string>) {
      state.alertSearchTerm = action.payload;
    },
    changeAlertCritical(state, action: PayloadAction<boolean>) {
      state.alertCritical = action.payload;
    },
    changeAlertStatus(state, action: PayloadAction<AlertStatusType>) {
      state.alertStatus = action.payload;
    },
    updateAlertPageSelected(state, action: PayloadAction<number>) {
      state.alertPageSelected = action.payload;
    },
    updateSelectedAlertData(state, action: PayloadAction<AlertCardData>) {
      state.selectedAlertData = action.payload;
    },
    updateExpiredAlertFilters(state, action: PayloadAction<any>) {
      state.expiredAlertFilters = {
        ...state.expiredAlertFilters,
        ...action.payload,
      };
    },
    resetExpiredAlertFilters(state) {
      state.expiredAlertFilters = {
        zoneId: '',
        zoneStatus: 0,
        time: calendarDefaultSettings,
      };
    },
  },
});

export const AlertActions = alertSlice.actions;
